// src/components/AudioPlayer.js

import React, { useState, useEffect, useRef } from "react";
import { makeStyles, IconButton, Avatar } from "@material-ui/core";
import { PlayArrow, Pause, Mic } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  audioPlayer: {
    display: "inline-flex",
    alignItems: "center",
    background: "#262d31",
    borderRadius: "0.4rem",
    padding: "0.4rem",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
    color: "#c5c6c8",
    minWidth: 240,
    width: 336,
    maxWidth: "100%",
    fontFamily: "Arial, sans-serif",
    margin: theme.spacing(1, 0),
    position: "relative",
    userSelect: "none",
  },
  audioPlayerMine: {
    flexDirection: "row-reverse",
    background: "#056162",
  },
  user: {
    position: "relative",
    width: 55,
    height: 55,
    marginLeft: theme.spacing(1.4),
    marginRight: theme.spacing(1.4),
  },
  userMine: {
    marginLeft: 0,
    marginRight: theme.spacing(1.4),
  },
  userImg: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    objectFit: "cover",
    background: "rgba(255, 255, 255, 0.01)",
  },
  micIcon: {
    position: "absolute",
    bottom: 0,
    left: "50%", // Centraliza horizontalmente
    transform: "translateX(-50%)",
    color: "#00e5c0",
    fontSize: "1.6rem",
    textShadow: `
      -1px -1px 0 #262d31,
      1px -1px 0 #262d31,
      -1px 1px 0 #262d31,
      1px 1px 0 #262d31
    `,
  },
  player: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  controls: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  btnPlay: {
    outline: "none",
    cursor: "pointer",
    background: "none",
    border: 0,
    padding: "0 0.8rem 0 0.4rem",
    color: "#c5c6c8",
    fontSize: "38px",
    transition: "color 0.3s",
    "&:hover": {
      color: "#00e5c0",
    },
    "&:disabled": {
      cursor: "default",
      color: "#b2dfdb",
    },
  },
  timeline: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(1),
    position: "relative",
  },
  line: {
    position: "relative",
    width: "100%",
    height: "4px",
    borderRadius: "2px",
    background: "rgba(255, 255, 255, 0.2)",
    cursor: "pointer",
    transition: "background 0.3s",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.3)",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "4px",
      borderRadius: "2px",
      background: "#00e5c0",
      width: (props) => props.percentPlayed + "%",
      transition: "width 0.3s",
      zIndex: 1,
    },
  },
  slider: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    // Remover opacity para tornar o slider visível
    opacity: 1,
    // Customizar o thumb para que apareça corretamente
    "&::-webkit-slider-thumb": {
      appearance: "none",
      width: "12px",
      height: "12px",
      background: "#00e5c0",
      borderRadius: "50%",
      cursor: "pointer",
      border: "none",
      marginTop: "-4px",
      zIndex: 2,
    },
    "&::-moz-range-thumb": {
      appearance: "none",
      width: "12px",
      height: "12px",
      background: "#00e5c0",
      borderRadius: "50%",
      cursor: "pointer",
      border: "none",
      zIndex: 2,
    },
    "&::-ms-thumb": {
      appearance: "none",
      width: "12px",
      height: "12px",
      background: "#00e5c0",
      borderRadius: "50%",
      cursor: "pointer",
      border: "none",
      zIndex: 2,
    },
    "&::-webkit-slider-runnable-track": {
      background: "transparent",
    },
    "&::-moz-range-track": {
      background: "transparent",
    },
    "&::-ms-track": {
      background: "transparent",
      borderColor: "transparent",
      color: "transparent",
    },
  },
  data: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.68rem",
    color: "#c5c6c8",
    width: "100%",
    paddingTop: "4px",
  },
  timestamp: {
    textAlign: "center",
    fontSize: "0.7rem",
    color: "#999",
    marginTop: "10px",
  },
}));

const AudioPlayer = ({ audioUrl, isMine, userImage, timestamp }) => {
  const [percentPlayed, setPercentPlayed] = useState(0);
  const classes = useStyles({ percentPlayed });
  const audioRef = useRef(new Audio(audioUrl));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [duration, setDuration] = useState("00:00");

  useEffect(() => {
    const audio = audioRef.current;

    const handleLoadedData = () => {
      setDuration(formatTime(audio.duration));
      setIsLoading(false);
    };

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(formatTime(audio.currentTime));
      setPercentPlayed((audio.currentTime / audio.duration) * 100);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime("00:00");
      setPercentPlayed(0);
      audio.currentTime = 0;
    };

    const handleLoadStart = () => {
      setIsLoading(true);
    };

    audio.addEventListener("loadeddata", handleLoadedData);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("loadstart", handleLoadStart);

    return () => {
      audio.pause();
      audio.removeEventListener("loadeddata", handleLoadedData);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("loadstart", handleLoadStart);
    };
  }, [audioUrl]);

  const formatTime = (seconds) => {
    if (isNaN(seconds)) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const handleSliderChange = (e) => {
    const audio = audioRef.current;
    const value = e.target.value;
    audio.currentTime = (value / 100) * audio.duration;
  };

  return (
    <div
      className={clsx(classes.audioPlayer, {
        [classes.audioPlayerMine]: isMine,
      })}
    >
      {/* Avatar do Usuário */}
      <div
        className={clsx(classes.user, {
          [classes.userMine]: isMine,
        })}
      >
        <Avatar src={userImage} className={classes.userImg} />
        <Mic className={classes.micIcon} />
      </div>

      <div className={classes.player}>
        <div className={classes.controls}>
          {/* Botão Play/Pause */}
          <IconButton
            className={classes.btnPlay}
            onClick={togglePlay}
            disabled={isLoading}
            aria-label={isPlaying ? "Pause audio" : "Play audio"}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>

          {/* Barra de Progresso */}
          <div className={classes.timeline}>
            <div className={classes.line}>
              <input
                type="range"
                min="0"
                max="100"
                value={percentPlayed}
                onChange={handleSliderChange}
                className={classes.slider}
              />
            </div>
            <div className={classes.data}>
              <span>{currentTime}</span>
              <span>{duration}</span>
            </div>
          </div>
        </div>
        {/* Hora de Envio */}
        {timestamp && (
          <div className={classes.timestamp}>
            Enviado às {timestamp}
          </div>
        )}
      </div>
    </div>
  );
};

export default AudioPlayer;
